import React from "react";
import styles from "./PricingList.module.css";
import "../../styles.css";
function PricingList() {
  return (
    <div className={styles.pricingListSection}>
      <div className={styles.pricingListContainer}>
        <span className="heading3 text-gray-500 ">Cjenik usluga</span>
        <div className={styles.pricingListGeneralServices}>
          <div className={styles.pricingListItems}>
            <div className={styles.pricingItemsTitle}>
              <span className="subheading1 text-mint uppercase-letters">
                Hidroterapija
              </span>
              <span className="body-regular text-gray-400">
                uključuje kupku, masažu i vježbe
              </span>
            </div>
            <div className={styles.items}>
              <div className={styles.item}>
                <span className="body-regular text-gray-500">
                  Baby Spa termin (50 min)
                </span>
                <span className={styles.divider} />
                <span className="body-regular text-gray-400">45€</span>
              </div>
              <div className={styles.item}>
                <span className="body-regular text-gray-500">
                  Baby Spa paket 4+1
                </span>
                <span className={styles.divider} />
                <span className="body-regular text-gray-400">180€</span>
              </div>
              <div className={styles.item}>
                <span className="body-regular text-gray-500">
                  Baby Spa paket 6+2
                </span>
                <span className={styles.divider} />
                <span className="body-regular text-gray-400">270€</span>
              </div>
              <div className={styles.item}>
                <span className="body-regular text-gray-500">
                  Baby Spa friends 1+1
                </span>
                <span className={styles.divider} />
                <span className="body-regular text-gray-400">80€</span>
              </div>
            </div>
          </div>
          <div className={styles.pricingListItems}>
            <div className={styles.pricingItemsTitle}>
              <span className="subheading1 text-mint uppercase-letters">
                Prenatalna masaža
              </span>
              <span className="body-regular text-gray-400">
                (masaža za trudnice)
              </span>
            </div>
            <div className={styles.items}>
              <div className={styles.item}>
                <span className="body-regular text-gray-500">
                  Cijelo tijelo (50 min)
                </span>
                <span className={styles.divider} />
                <span className="body-regular text-gray-400">35€</span>
              </div>
              <div className={styles.item}>
                <span className="body-regular text-gray-500">Kinesiotape</span>
                <span className={styles.divider} />
                <span className="body-regular text-gray-400">5€</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.pricingListGeneralServices}>
          <div className={styles.pricingListItems}>
            <div className={styles.pricingItemsTitle}>
              <span className="subheading1 text-mint uppercase-letters">
                Ostalo
              </span>
            </div>
            <div className={styles.items}>
              <div className={styles.item}>
                <span className="body-regular text-gray-500">
                  Poklon bon Baby Spa
                </span>
                <span className={styles.divider} />
                <span className="body-regular text-gray-400">€</span>
              </div>
            </div>
          </div>
          <div className={styles.pricingListItems}>
            <div className={styles.pricingItemsTitle}>
              <span className="subheading1 text-mint uppercase-letters">
                Trening za trudnice
              </span>
              <span className="body-regular text-gray-400">
                (pod vodstvom iskusne fizioterapeutkinje)
              </span>
            </div>
            <div className={styles.items}>
              <div className={styles.item}>
                <span className="body-regular text-gray-500">
                  Grupni trening{" "}
                </span>
                <span className={styles.divider} />
                <span className="body-regular text-gray-400">na upit</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PricingList;
