import React from "react";
import styles from "./AboutBabySpa.module.css";
import "../../styles.css";
function AboutBabySpa() {
  return (
    <div className={styles.aboutBabySpaSection}>
      <div className={styles.aboutBabySpaContainer}>
        <div className={styles.imageContainer}>
          <div className={styles.image} />
        </div>
        <div className={styles.textContainer}>
          <span className="label-medium-Inter text-gray-100  uppercase-letters">
            S ljubavlju za najmanje
          </span>
          <div className={styles.mainHeadingAbout}>
            <span className="heading3 text-gray-500 ">Upoznajte naš</span>
            <span className="heading3 text-mint">Baby Spa</span>
          </div>
          <div className={styles.contentAbout}>
            <p className="body-regular text-gray-400">
              Active Baby Concept nastao je iz želje da se bebama pruži
              opuštajuće iskustvo koje potiče razvoj. Dok su hidroterapija i
              masaža beba u svijetu prisutne već preko 15 godina, u Dalmaciji
              smo prvi s ovakvim konceptom pristupa s najslađom nam dobnom
              skupinom.
            </p>
            <p className="body-regular text-gray-400">
              Naš cilj je da Active Baby Concept postane sinonim stručnosti i
              sigurnosti te da olakšamo i uljepšamo djetinjstvo svakog mališana.
            </p>
          </div>
          <a
            className={styles.ctaButton}
            href="https://tinyurl.com/ActiveBabyConceptReviews"
            target="_blank"
            rel="noopener noreferrer"
            aria-label="Otvori Google recenzije u novom prozoru"
          >
            <span className="label-medium-Inter text-white cursor-pointer">
              Iskustva korisnika
            </span>
          </a>
        </div>
      </div>
    </div>
  );
}

export default AboutBabySpa;
